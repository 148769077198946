import React from 'react';
import { PoseGroup } from 'react-pose';
import './checkin.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import {VerticalAccordionContainer} from '../utils/pose_containers';
import * as routes from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        ALERT_FUNCTIONAL_CHECKIN_COUNT_THRESHOLD,
        MEDIUM_FUNCTIONAL_CHECKIN_COUNT_THRESHOLD,
        ALERT_PILATES_CHECKIN_COUNT_THRESHOLD,
        MEDIUM_PILATES_CHECKIN_COUNT_THRESHOLD,
        ALERT_GYM_CHECKIN_COUNT_THRESHOLD,
        MEDIUM_GYM_CHECKIN_COUNT_THRESHOLD,
        ALERT_BIKE_CHECKIN_COUNT_THRESHOLD,
        MEDIUM_BIKE_CHECKIN_COUNT_THRESHOLD,
        ALERT_CARDIO_CHECKIN_COUNT_THRESHOLD,
        MEDIUM_CARDIO_CHECKIN_COUNT_THRESHOLD,
        ALERT_BOOTCAMP_CHECKIN_COUNT_THRESHOLD,
        MEDIUM_BOOTCAMP_CHECKIN_COUNT_THRESHOLD,
        ALERT_CORE_CHECKIN_COUNT_THRESHOLD,
        MEDIUM_CORE_CHECKIN_COUNT_THRESHOLD,
        SERVICE_DEFAULT_TRAINING,
        SERVICE_PILATES,
        SERVICE_GYM,
        SERVICE_BIKE,
        SERVICE_CARDIO,
        SERVICE_BOOTCAMP,
        SERVICE_CORE,
        TRAINING_EXECUTION_METHOD_FIXED_REPS,
        TRAINING_EXECUTION_METHOD_PIRAMIDAL,
        TRAINING_EXECUTION_METHOD_QRP,
        AVAILABLE_CHECKIN_HOURS_AHEAD,
        ACCESS_CONTROL_RULE_BLOCKED} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import ConfirmationWindow from '../components/confirmation_window';
import OverlayWindow from '../components/overlay_window';
import {getModel, postModel, getAsLocalDate, parseTextForIcons} from '../utils/functions';

const CLASS_CHECKIN_STATUS_AVAILABLE = 'AVAILABLE';
const CLASS_CHECKIN_STATUS_UNAVAILABLE = 'UNAVAILABLE';
const CLASS_CHECKIN_STATUS_LIMITED = 'LIMITED';
const CLASS_CHECKIN_STATUS_ALERT = 'ALERT';

const DEFAULT_SEQUENTIAL_COLOR_PALLET = [
  '#367dc4',
  '#da5a1b',
  '#0e8c62',
  '#e32b62',
  '#8746ce',
  '#0e8f9d',
  '#3945e4',
  '#a92689',
  '#547582',
  '#e24c4c',
  '#245a8e',
  '#74abe2',
  '#ef8d5d',
  '#3fb68e',
  '#f06a93',
  '#a97dd8',
  '#3ab5c2',
  '#6973f6',
  '#cd59b1',
  '#8ca2ab',
  '#f38787',
  '#4d78a2',
];

function getFormatedTime(minutes) {
  const secondsRemaining = Math.floor((minutes*60) % 60);

  return `${Math.floor(minutes)}'` + (secondsRemaining > 0 ? ` ${secondsRemaining}''` : '');
}

const QRP_CYCLES_VISIBLE_NUMBER = 3;

class Checkin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      active_units: [],
      selectedUnit: null,
      services: [],
      service_map: {},
      dates_visible: {},
      selectedService: null,
      access_rule: null,
      access_status_description: null,
      removeCheckinFromClass: null,
      selectedClassForCheckin: null,
      showDefaultErrorPanel: false,
      pse: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      training_day_map: new Map(),
      training_periods: [],
      trainingDaySelected: null,
      criticalErrorMessage: null,
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getActiveUnits() {
    return await getModel(routes.ACTIVE_UNITS_GET_API);
  }

  async getCalendar() {
    if(this.state.selectedUnit === null) {
      return {};
    }

    return await getModel(`${routes.TRAINING_CALENDAR_GET_API}${this.state.selectedUnit.id}`);
  }

  async getAccessRule() {
    if(this.state.selectedUnit === null) {
      return {};
    }

    return await getModel(`${routes.ACCESS_CONTROL_ACCESS_RULE_GET_API}${this.state.selectedUnit.id}`);
  }

  async getCurrentPeriods() {
    if(this.state.selectedUnit === null || this.state.selectedService === null) {
      return;
    }

    this.setState({
      loading: true
    });

    const update = {loading: false};

    const currentPeriods = await getModel(`${routes.CURRENT_TRAINING_PERIODS_GET_API}${this.state.selectedUnit.id}/${this.state.selectedService}`);

    if(currentPeriods) {
      update.training_day_map = new Map();

      for(let entry of currentPeriods.trainings) {
        if(entry.has_training && this.state.services.includes(entry.target_service)) {
          if(!update.training_day_map.has(entry.target_service)) {
            update.training_day_map.set(entry.target_service, new Map());
          }

          const dateMap = update.training_day_map.get(entry.target_service);

          if(!dateMap.has(entry.date)) {
            dateMap.set(entry.date, entry);
          }
        }
      }

      update.training_periods = currentPeriods.periods;
    }

    this.setState(update);
  }

  async reloadCalendar() {
    this.setState({
      loading: true
    });

    const update = {loading: false};

    let access_rule = null;

    if (this.state.access_rule === null) {
      access_rule = this.getAccessRule();
    }

    let calendar = this.getCalendar();

    if (this.state.access_rule === null) {
      access_rule = await access_rule;

      if (access_rule) {
        update.access_rule = access_rule.access_rule;
        update.access_status_description = access_rule.access_status_description;
      }
    }

    calendar = await calendar;

    if(calendar) {
      update.service_map = calendar.service_map;
      update.services = calendar.services;
      update.services.sort((a, b) => a.localeCompare(b));

      if(calendar.services.length === 1) {
        if(update.service_map[calendar.services[0]].contract_not_accepted) {
          update.criticalErrorMessage = (
            <span>

              Para liberar a funcionalidade de checkin antecipado é necessário que o contrato do serviço selecionado seja aceito. Este procedimento pode ser realizado clicando-se
              <a
                className="checkin__external-text-link"
                href={update.service_map[calendar.services[0]].contract_accept_link}
                target="_blank"
                rel="noopener noreferrer"
              >

                  aqui

              </a>

            </span>
          );
        }
        else {
          update.selectedService = calendar.services[0];
        }
      }
    }

    this.setState(update);
  }

  async componentDidMount() {
    const update = {loading: false};

    try {
      let active_units = await this.getActiveUnits();

      if(active_units) {
        update.active_units = active_units;

        if(update.active_units.length === 1) {
          if(update.active_units[0].registration_incomplete) {
            update.criticalErrorMessage = (
              <span>

                Por favor, finalize o procedimento de cadastro de aluno para liberar o checkin antecipado clicando
                <a
                  className="checkin__external-text-link"
                  href={update.active_units[0].registration_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >

                    aqui

                </a>

              </span>
            );
          }
          else {
            update.selectedUnit = update.active_units[0];
          }
        }
      }
    }
    catch(errors) {
      update.criticalErrorMessage = DEFAULT_UNKNOWN_ERROR_MESSAGE;
      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 208:
              if(error.message.startsWith('User registration is incomplete.')) {
                update.criticalErrorMessage = 'Por favor, finalize o procedimento de cadastro de aluno para liberar o checkin antecipado.';
              }

              break;
            case 209:
              update.criticalErrorMessage = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedUnit !== this.state.selectedUnit) {
      this.reloadCalendar();
    }
    else if (prevState.selectedService !== this.state.selectedService) {
      this.getCurrentPeriods();
    }
  }

  onSelectUnit(unit) {
    if(unit.registration_incomplete) {
      this.setState({
        criticalErrorMessage: (
          <span>

            Por favor, finalize o procedimento de cadastro de aluno para liberar o checkin antecipado clicando
            <a
              className="checkin__external-text-link"
              href={unit.registration_link}
              target="_blank"
              rel="noopener noreferrer"
            >

                aqui

            </a>

          </span>
        )
      });
    }
    else {
      this.setState({
        selectedUnit: unit
      });
    }
  }

  onSelectService(service) {
    if(this.state.service_map[service].contract_not_accepted) {
      this.setState({
        criticalErrorMessage: (
          <span>

            Para liberar a funcionalidade de checkin antecipado é necessário que o contrato do serviço selecionado seja aceito. Este procedimento pode ser realizado clicando-se
            <a
              className="checkin__external-text-link"
              href={this.state.service_map[service].contract_accept_link}
              target="_blank"
              rel="noopener noreferrer"
            >

                aqui

            </a>

          </span>
        )
      });
    }
    else {
      this.setState({
        selectedService: service,
        dates_visible: {}
      })
    }
  }

  getDatetimeText(isoDatetime) {
    const date = getAsLocalDate(isoDatetime);

    const dateFormat = {
      day: '2-digit',
      weekday: 'long'
    };

    let dateText = '';

    // if(this.state.screenWidth > 515) {
    //   dateFormat.weekday = 'short';
    // }

    // if(this.state.screenWidth > 580) {
    //   dateFormat.weekday = 'long';
    // }

    dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    return dateText;
  }

  getServiceOptions() {
    if(this.state.services.length <= 0) {
      return (
        <div className="checkin__warning-message">

          <i className="fas fa-exclamation checkin__warning-message__icon"></i>
          <p className="checkin__warning-message__text">Nenhum serviço contratado.</p>

        </div>
      );
    }

    return this.state.services.map((service, index) => (
      <DefaultMenuButton
        key={`checkin:service_selector:${index}`}
        className="checkin__service-button"
        onClick={() => this.onSelectService(service)}
        text={service}
        color="purple"
      />
    ));
  }

  getUnitOptions() {
    return this.state.active_units.map((unit, index) => (
      <DefaultMenuButton
        key={`checkin:unit_selector:${unit.id}`}
        className="checkin__unit-button"
        onClick={() => this.onSelectUnit(unit)}
        text={unit.name}
        color="blue"
      />
    ));
  }

  getTimeEntries(entry) {
    let MID_THRESHOLD = 0;
    let ALERT_THRESHOLD = 0;

    switch (this.state.selectedService) {
      case SERVICE_DEFAULT_TRAINING:
        MID_THRESHOLD = MEDIUM_FUNCTIONAL_CHECKIN_COUNT_THRESHOLD;
        ALERT_THRESHOLD = ALERT_FUNCTIONAL_CHECKIN_COUNT_THRESHOLD;
        break;
      case SERVICE_PILATES:
        MID_THRESHOLD = MEDIUM_PILATES_CHECKIN_COUNT_THRESHOLD;
        ALERT_THRESHOLD = ALERT_PILATES_CHECKIN_COUNT_THRESHOLD;
        break;
      case SERVICE_GYM:
        MID_THRESHOLD = ALERT_GYM_CHECKIN_COUNT_THRESHOLD;
        ALERT_THRESHOLD = MEDIUM_GYM_CHECKIN_COUNT_THRESHOLD;
        break;
      case SERVICE_BIKE:
        MID_THRESHOLD = ALERT_BIKE_CHECKIN_COUNT_THRESHOLD;
        ALERT_THRESHOLD = MEDIUM_BIKE_CHECKIN_COUNT_THRESHOLD;
        break;
      case SERVICE_CARDIO:
        MID_THRESHOLD = ALERT_CARDIO_CHECKIN_COUNT_THRESHOLD;
        ALERT_THRESHOLD = MEDIUM_CARDIO_CHECKIN_COUNT_THRESHOLD;
        break;
      case SERVICE_BOOTCAMP:
        MID_THRESHOLD = ALERT_BOOTCAMP_CHECKIN_COUNT_THRESHOLD;
        ALERT_THRESHOLD = MEDIUM_BOOTCAMP_CHECKIN_COUNT_THRESHOLD;
        break;
      case SERVICE_CORE:
        MID_THRESHOLD = ALERT_CORE_CHECKIN_COUNT_THRESHOLD;
        ALERT_THRESHOLD = MEDIUM_CORE_CHECKIN_COUNT_THRESHOLD;
        break;
      default:
    }

    const AVAILABLE_TEXT = this.state.screenWidth > 400 ? 'vagas disponíveis' : 'vagas';
    const ALERT_TEXT = this.state.screenWidth > 400 ? 'vagas disponíveis' : 'vagas';
    const UNAVAILABLE_TEXT = this.state.screenWidth > 400 ? 'Vagas esgotadas' : 'Esgotadas';

    return entry.times.map((time, index) => {
      let status = CLASS_CHECKIN_STATUS_AVAILABLE;

      if(time.available_slots !== null) {
        if(time.available_slots <= 0) {
          status = CLASS_CHECKIN_STATUS_UNAVAILABLE;
        }
        else if(time.available_slots <= ALERT_THRESHOLD) {
          status = CLASS_CHECKIN_STATUS_ALERT;
        }
        else if(time.available_slots <= MID_THRESHOLD) {
          status = CLASS_CHECKIN_STATUS_LIMITED;
        }
      }

      return (
        <React.Fragment
          key={`checkin:time_selector:date:${entry.date}:time:${time.time}`}
        >

          {index > 0 &&
            <hr className="checkin__horizontal-rule" />
          }

          <div className="checkin__time">

            <div className="checkin__time__text-wrapper">

              <h4 className="checkin__time__text">{time.time}</h4>

              {/* {!time.has_checked_in &&
                <p className={`checkin__time__status-text${status === CLASS_CHECKIN_STATUS_ALERT ? '--alert' : status === CLASS_CHECKIN_STATUS_LIMITED ? '--limited' : status === CLASS_CHECKIN_STATUS_UNAVAILABLE ? '--disabled' : ''}`}>

                  {status === CLASS_CHECKIN_STATUS_ALERT ? `${time.available_slots} ${ALERT_TEXT}` : status === CLASS_CHECKIN_STATUS_UNAVAILABLE ? UNAVAILABLE_TEXT : `${time.available_slots} ${AVAILABLE_TEXT}`}

                </p>
              } */}

              <p className={`checkin__time__status-text${status === CLASS_CHECKIN_STATUS_ALERT ? '--alert' : status === CLASS_CHECKIN_STATUS_LIMITED ? '--limited' : status === CLASS_CHECKIN_STATUS_UNAVAILABLE ? '--disabled' : ''}`}>

                {status === CLASS_CHECKIN_STATUS_ALERT ? `${time.available_slots} ${ALERT_TEXT}` : status === CLASS_CHECKIN_STATUS_UNAVAILABLE ? UNAVAILABLE_TEXT : `${time.available_slots} ${AVAILABLE_TEXT}`}

              </p>

            </div>

            {status !== CLASS_CHECKIN_STATUS_UNAVAILABLE || time.has_checked_in ?
              (time.has_checked_in ?
                <DefaultMenuButton
                  className="checkin__checkin-button"
                  onClick={() => this.setState({
                    removeCheckinFromClass: {
                      date: entry.date,
                      time: time.time,
                      unit_id: this.state.selectedUnit.id,
                      service: this.state.selectedService,
                    },
                  })}
                  text="Cancelar"
                  color="red"
                />:
                <DefaultMenuButton
                  className="checkin__checkin-button"
                  onClick={() => this.setState({
                    selectedClassForCheckin: {
                      date: entry.date,
                      time: time.time,
                      unit_id: this.state.selectedUnit.id,
                      service: this.state.selectedService,
                    },
                    pse: null
                  })}
                  text="Check-in"
                  color="green"
                />):
              null
            }

          </div>

        </React.Fragment>
      );
    });
  }

  onViewTrainingDay(trainingDateEntry) {
    const period = this.state.training_periods.find(entry => entry.id === trainingDateEntry.period_id);

    const trainingDay = period.training_days.find(entry => entry.id === trainingDateEntry.training_day_id);

    this.setState({trainingDaySelected: {...trainingDay, repetition_index: trainingDateEntry.repetition_index}});
  }

  getDays() {
    const dates = this.state.service_map[this.state.selectedService].calendar;

    if(dates.length <= 0) {
      return (
        <div className="checkin__warning-message">

          <i className="fas fa-exclamation checkin__warning-message__icon"></i>
          <p className="checkin__warning-message__text">Nenhum dia disponível.</p>

        </div>
      );
    }

    return dates.map((date) => {
      if(date.times.length <= 0) {
        return (
          <section
            key={`checkin:service:${this.state.selectedService}:date-options:${date.date}`}
            className="checkin__date"
          >

            <header
              className="checkin__date__header--disabled"
            >

              <h3 className="checkin__date__header__text">
                {/* <i className="far fa-chart-bar checkin__date__header__text-icon"></i> */}
                {this.getDatetimeText(date.date)}
              </h3>

              <p className="checkin__date__header__disabled-text">Indisponível</p>

            </header>

          </section>
        );
      }

      const dates_visible = {...this.state.dates_visible};
      dates_visible[date.date] = !(dates_visible[date.date] || false);

      let trainingDateMap = this.state.training_day_map.get(this.state.selectedService);

      return (
        <section
          key={`checkin:service:${this.state.selectedService}:date-options:${date.date}`}
          className="checkin__date"
        >

          <header
            className="checkin__date__header"
            onClick={() => this.setState({dates_visible})}
          >

            <h3 className="checkin__date__header__text">
              {/* <i className="far fa-chart-bar checkin__date__header__text-icon"></i> */}
              {this.getDatetimeText(date.date)}
            </h3>

            {this.state.dates_visible[date.date] ?
              <i className="fas fa-chevron-down checkin__date__header__visible-icon"></i>:
              <i className="fas fa-chevron-up checkin__date__header__visible-icon"></i>
            }

          </header>

          <VerticalAccordionContainer
            className="vertical-accordion-container checkin__date__content"
            pose={this.state.dates_visible[date.date] ? 'verticalOpen' : 'verticalClosed'}>

            <div className="vertical-accordion-container checkin__date__content-wrapper">

              {(trainingDateMap && trainingDateMap.has(date.date)) &&
                <DefaultMenuButton
                  className="checkin__date__view-training-button"
                  onClick={() => this.onViewTrainingDay(trainingDateMap.get(date.date))}
                  text="Ver treino"
                  color="black"
                />
              }

              {this.getTimeEntries(date)}

            </div>

          </VerticalAccordionContainer>

        </section>
      );
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.selectedClassForCheckin !== null) {
        return 'Falha ao confirmar check-in';
      }
      else if(this.state.removeCheckinFromClass !== null) {
        return 'Falha ao cancelar check-in';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.selectedClassForCheckin !== null) {
        return 'Confirmando check-in';
      }
      else if(this.state.removeCheckinFromClass !== null) {
        return 'Cancelando check-in';
      }
    }
    else {
      if(this.state.selectedClassForCheckin !== null) {
        return 'Confirmar check-in';
      }
      else if(this.state.removeCheckinFromClass !== null) {
        return 'Cancelar check-in';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.selectedClassForCheckin !== null) {
      if (this.state.confirmInProgress) {
        return `Aguarde enquanto confirmamos seu checkin das ${this.state.selectedClassForCheckin.time}.`;
      }

      return `Deseja realmente confirmar seu check-in para a aula das ${this.state.selectedClassForCheckin.time}?`;
    }
    else if(this.state.removeCheckinFromClass !== null) {
      if (this.state.confirmInProgress) {
        return `Aguarde enquanto cancelamos seu checkin das ${this.state.removeCheckinFromClass.time}.`;
      }

      return `Deseja realmente cancelar seu check-in para a aula das ${this.state.removeCheckinFromClass.time}?`;
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.selectedClassForCheckin !== null) {
      return 'Confirmar';
    }
    else if(this.state.removeCheckinFromClass !== null) {
      return 'Confirmar cancelamento';
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.removeCheckinFromClass !== null || this.state.showDefaultErrorPanel || this.state.confirmInProgress;
  }

  resetConfirmationWindow() {
    this.setState({
      removeCheckinFromClass: null,
      selectedClassForCheckin: null,
      confirmFailed: false,
      confirmInProgress: false,
      pse: null,
      showDefaultErrorPanel: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.state.selectedClassForCheckin !== null) {
      this.setState({
        confirmInProgress: true
      });

      const data = {...this.state.selectedClassForCheckin};
      data.pse = this.state.pse;

      try{
        if(await postModel(routes.CHECKIN_CONFIRM_POST_API, data)) {
          this.setState({
            selectedClassForCheckin: null,
            pse: null,
            confirmFailed: false,
            confirmInProgress: false,
          });

          this.reloadCalendar();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 106:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'checkin_limit':
                      this.reloadCalendar();
                      errorDescription = 'Infelizmente todas as vagas já foram preenchidas para o dia e horário selecionados.';

                      break;
                    default:
                  }
                }

                break;
              case 208:
                if(error.message.startsWith('Already checked in for given service')) {
                  errorDescription = 'É permitido apenas um checkin ativo por serviço no mesmo dia. Para realizar o checkin neste horário, por favor, cancele seu outro checkin neste dia para este mesmo serviço ou tente realizar o checkin após sua aula marcada.';
                }
                else if(error.message.startsWith('Already checked in at this time for another service')) {
                  let serviceText = 'INDEFINIDO';

                  for(let parameter of error.parameters) {
                    if (parameter.name === 'service') {
                      serviceText = parameter.value;
                    }
                  }
                  errorDescription = `Você já possui um checkin ativo neste horário para o serviço de ${serviceText}. Para realizar o checkin neste horário, por favor, cancele seu outro checkin primeiro.`;
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          showDefaultErrorPanel: true,
        });

        return;
      }
    }
    else if(this.state.removeCheckinFromClass !== null) {
      this.setState({
        confirmInProgress: true
      });

      const data = {...this.state.removeCheckinFromClass};

      try{
        if(await postModel(routes.CHECKIN_CANCEL_POST_API, data)) {
          this.setState({
            removeCheckinFromClass: null,
            confirmFailed: false,
            confirmInProgress: false,
          });

          this.reloadCalendar();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    return;
  }

  overlayIsVisible() {
    return this.state.selectedClassForCheckin !== null;
  }

  getOverlayHeader() {
    // if(this.state.confirmFailed) {
    //   return this.state.confirmFailDescription;
    // }
    if(this.state.selectedClassForCheckin !== null) {
      return (
        <span>Confirme seu check-in para a aula das <span className="checkin__overlay__header__red-text">{this.state.selectedClassForCheckin.time}</span></span>
      );
    }

    return 'Não implementado';
  }

  getTrainingDayHeader() {
    if(this.state.trainingDaySelected) {
      return (
        <span>
          Treino <span className="checkin__overlay__header__red-text">{this.state.trainingDaySelected.name}</span>
          {` (FASE ${this.state.trainingDaySelected.repetition_index})`}
        </span>
      );
    }

    return 'Treino não disponível';
  }

  getTrainingDayView() {
    if(this.state.trainingDaySelected) {
      return (
        <React.Fragment>

          {(this.state.trainingDaySelected.note && this.state.trainingDaySelected.note.length > 0) &&
            <div className="checkin__training__note">

              <i className="fas fa-exclamation checkin__training__note-icon"></i>

              <p className="checkin__training__note-text">

                {this.state.trainingDaySelected.note}

              </p>

            </div>
          }

          {this.getTraingGroups(this.state.trainingDaySelected.repetition_index, this.state.trainingDaySelected)}

        </React.Fragment>
      );
    }

    return '';
  }

  getTraingGroups(repetitionIndex, trainingData) {
    const groupInfo = [];

    for(let exerciseGroup of trainingData.group_associations) {
      let groupColor = '#000000';

      const groupOrderIndex = exerciseGroup.order - 1;

      if(groupOrderIndex < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
          groupColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[groupOrderIndex];
      }

      groupInfo.push(
        <div
          className="checkin__training__exercise-group"
          style={{borderColor: groupColor, background: groupColor}}
          key={`checkin:exercise_group:${exerciseGroup.id}:repetition:${repetitionIndex}`}
        >

          <h4
            className="checkin__training__exercise-group__title"
            style={{background: groupColor}}
          >

            {exerciseGroup.name}

          </h4>

          <div className="checkin__training__exercise-group__exercises-container">

            {this.getTrainingExercises(exerciseGroup.execution_method, exerciseGroup.group_is_phase_constant ? 1 : repetitionIndex, exerciseGroup.exercise_associations, groupColor, exerciseGroup.cycle_number, exerciseGroup)}

          </div>

          {exerciseGroup.note ?
            <div className="checkin__training__exercise-group__note-container">

              <p className="checkin__training__exercise-group__note-label">
                OBS:
              </p>

              <p
                className="checkin__training__exercise-group__note-text"
                style={{color: groupColor}}
              >
                {exerciseGroup.note}
              </p>

            </div>:
            null
          }

        </div>
      );
    }

    return groupInfo;
  }

  getTrainingExercises(executionMethod, repetitionIndex, exercises, groupColor, cycleNumber, exerciseGroup=null) {
    if(exercises.length <= 0) {
      return (
        <p className="checkin__training__exercise__not-configured">TREINO NÃO CONFIGURADO</p>
      );
    }

    let intensities = [];
    const difficulties = [];
    const intermediateDifficulties = [];
    const advancedDifficulties = [];
    const exerciseNames = [];
    const easierOptions = [];
    const references = [];

    let showDificulties = false;
    let showIntermediateDificulties = false;
    let showAdvancedDificulties = false;
    let showEasierOptions = false;
    let showReferences = false;
    let showCycleNumber = true;

    let hasAdditionalHeader = false;

    let cycleNumberText = `${cycleNumber}x`;

    if(executionMethod === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
      showCycleNumber = false;
      hasAdditionalHeader = true;

      for(let i = 0; i < cycleNumber; ++i) {
        intensities.push([(
          <p
            className="checkin__training__exercise__header"
            key={`checkin:repetition:${repetitionIndex}:intensity_header:cycle:${i}`}
            style={{background: groupColor}}
          >

            {i + 1}

          </p>
        )]);
      }
    }
    else if(executionMethod === TRAINING_EXECUTION_METHOD_QRP) {
      cycleNumberText = (
        <React.Fragment>

          <span className="checkin__training__exercise__reps-text-span">
            QRP
          </span>

          {exerciseGroup.clock_time_limit ?
            <span className="checkin__training__exercise__reps-text-span">
              {getFormatedTime(exerciseGroup.clock_time_limit)}
            </span>:
            null
          }

        </React.Fragment>
      );
      hasAdditionalHeader = true;

      for(let i = 0; i < QRP_CYCLES_VISIBLE_NUMBER + 1; ++i) {
        intensities.push([(
          <p
            className="checkin__training__exercise__header"
            key={`checkin:repetition:${repetitionIndex}:intensity_header:cycle:${i}`}
            style={{background: groupColor}}
          >

            {i === QRP_CYCLES_VISIBLE_NUMBER ? 'N' : (i + 1)}

          </p>
        )]);
      }
    }

    for(let exercise of exercises) {
      if(!showDificulties && exercise.difficult_value[repetitionIndex-1]) {
        showDificulties = true;
      }

      if(!showIntermediateDificulties && exercise.difficult_intermediate_value[repetitionIndex-1]) {
        showIntermediateDificulties = true;
      }

      if(!showAdvancedDificulties && exercise.difficult_advanced_value[repetitionIndex-1]) {
        showAdvancedDificulties = true;
      }

      if(!showEasierOptions && exercise.easier_option && exercise.easier_option.length > 0) {
        showEasierOptions = true;
      }

      if(!showReferences && exercise.exercise.reference_url && exercise.exercise.reference_url.length > 0) {
        showReferences = true;
      }

      if(executionMethod === TRAINING_EXECUTION_METHOD_FIXED_REPS) {
        let intensityStyle = {};
        let intensityText;

        if(exercise.intensity_value[repetitionIndex-1] && exercise.intensity_value[repetitionIndex-1].trim().length > 0) {
          intensityText = exercise.intensity_value[repetitionIndex-1];
          intensityStyle = {};
        }
        else {
          intensityStyle.color = 'transparent';
          intensityText = '-';
        }

        intensities.push(
          <p
            className="checkin__training__exercise__intensity-text"
            key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:intensity`}
            style={intensityStyle}
          >

            {intensityText}

          </p>
        );
      }
      else if(executionMethod === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
        for(let i = 0; i < cycleNumber; ++i) {
          let intensityStyle = {};
          let intensityText;

          if(exercise.intensity_value[repetitionIndex-1] && exercise.intensity_value[repetitionIndex-1][i] && exercise.intensity_value[repetitionIndex-1][i].trim().length > 0) {
            intensityText = exercise.intensity_value[repetitionIndex-1][i];
          }
          else {
            intensityStyle.color = 'transparent';
            intensityText = '-';
          }

          intensities[i].push(
            <p
              className="checkin__training__exercise__intensity-cycle-text"
              key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:intensity:cycle:${i}`}
              style={intensityStyle}
            >

              {intensityText}

            </p>
          );
        }
      }
      else if(executionMethod === TRAINING_EXECUTION_METHOD_QRP) {
        let showElipsis = true;

        for(let i = 0; i < QRP_CYCLES_VISIBLE_NUMBER; ++i) {
          let intensity_value = exercise.intensity_value[repetitionIndex-1] + (exercise.intensity_value_step[repetitionIndex-1]*i);

          if(intensity_value <= 0) {
            intensity_value = '-';
            showElipsis = false;
          }
          else {
            intensity_value = `${intensity_value}${exercise.intensity_unit ? ' ' + exercise.intensity_unit : ''}`;
          }

          intensities[i].push(
            <p
              className="checkin__training__exercise__intensity-cycle-text"
              key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:intensity:cycle:${i}`}
            >

              {intensity_value}

            </p>
          );
        }

        intensities[QRP_CYCLES_VISIBLE_NUMBER].push(
          <p
            className="checkin__training__exercise__intensity-cycle-text"
            key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:intensity:cycle:${QRP_CYCLES_VISIBLE_NUMBER}`}
          >

            {showElipsis ?
              <i className="fas fa-ellipsis-h"></i>:
              '-'
            }

          </p>
        );
      }

      let difficultyStyle = {};
      let difficultyText;

      if(exercise.difficult_value[repetitionIndex-1]) {
        difficultyText = `${exercise.difficult_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        difficultyStyle.color = 'transparent';
        difficultyText = '-';
      }

      difficulties.push(
        <p
          className="checkin__training__exercise__difficult-text"
          key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:difficulty`}
          style={difficultyStyle}
        >

          {parseTextForIcons(difficultyText, `checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:dificult_text`, 'checkin__parsed-text', true)}

        </p>
      );

      let intermediateDifficultyStyle = {};
      let intermediateDifficultyText;

      if(exercise.difficult_intermediate_value[repetitionIndex-1]) {
        intermediateDifficultyText = `${exercise.difficult_intermediate_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        intermediateDifficultyStyle.color = 'transparent';
        intermediateDifficultyText = '-';
      }

      intermediateDifficulties.push(
        <p
          className="checkin__training__exercise__difficult-text"
          key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:intermediate_difficulty`}
          style={intermediateDifficultyStyle}
        >

          {parseTextForIcons(intermediateDifficultyText, `checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:dificult_text`, 'checkin__parsed-text', true)}

        </p>
      );

      let advancedDifficultyStyle = {};
      let advancedDifficultyText;

      if(exercise.difficult_advanced_value[repetitionIndex-1]) {
        advancedDifficultyText = `${exercise.difficult_advanced_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        advancedDifficultyStyle.color = 'transparent';
        advancedDifficultyText = '-';
      }

      advancedDifficulties.push(
        <p
          className="checkin__training__exercise__difficult-text"
          key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:advanced_difficulty`}
          style={advancedDifficultyStyle}
        >

          {parseTextForIcons(advancedDifficultyText, `checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:dificult_text`, 'checkin__parsed-text', true)}

        </p>
      );

      exerciseNames.push(
        <p
          className="checkin__training__exercise__name-text"
          key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:name`}
        >

          {exercise.exercise_name}

        </p>
      );

      let easierOptionStyle = {};
      let easierOptionText;

      if(exercise.easier_option) {
        easierOptionText = exercise.easier_option;
      }
      else {
        easierOptionStyle.color = 'transparent';
        easierOptionText = '-';
      }

      easierOptions.push(
        <p
          className="checkin__training__exercise__easier-option-text"
          key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:easier_option`}
          style={easierOptionStyle}
        >

          {easierOptionText}

        </p>
      );

      if(exercise.exercise.reference_url && exercise.exercise.reference_url.length > 0) {
        references.push(
          <a
            className="checkin__training__exercise__reference-link"
            href={exercise.exercise.reference_url}
            target="_blank"
            rel="noopener noreferrer"
            key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:reference`}
          >

            <i className="fas fa-link"></i>

          </a>
        );
      }
      else {
        references.push(
          <p
            className="checkin__training__exercise__reference-link"
            key={`checkin:exercise:${exercise.id}:repetition:${repetitionIndex}:reference`}
            style={{color: 'transparent'}}
          >
            -
          </p>
        );
      }
    }

    if(executionMethod !== TRAINING_EXECUTION_METHOD_FIXED_REPS) {
      intensities = intensities.map((entry, index) => (
        <div
          className="checkin__training__exercise__intensities"
          key={`checkin:repetition:${repetitionIndex}:intensity_cycle_column:${index}`}
        >

          {entry}

        </div>
      ));
    }

    return (
      <React.Fragment>

        <div
          className={`checkin__training__exercise__first-column${executionMethod === TRAINING_EXECUTION_METHOD_PIRAMIDAL ? '--expanded' : ''}`}
          style={{background: groupColor}}
        >

          <p
            className="checkin__training__exercise__header"
          >

          </p>

          {hasAdditionalHeader &&
            <p
              className="checkin__training__exercise__header"
              style={{background: groupColor}}
            >

              {executionMethod === TRAINING_EXECUTION_METHOD_PIRAMIDAL ? 'Série' : ''}

            </p>
          }

        </div>

        {showCycleNumber &&
          <div className="checkin__training__exercise__reps">

            <p
              className="checkin__training__exercise__header"
              style={{background: groupColor}}
            >

            </p>

            {hasAdditionalHeader &&
              <p
                className="checkin__training__exercise__header"
                style={{background: groupColor}}
              >

                Série

              </p>
            }

            <p className="checkin__training__exercise__reps-text">

              {cycleNumberText}

            </p>

          </div>
        }

        <div className={`checkin__training__exercise__intensities${hasAdditionalHeader ? '--collapsed' : ''}`}>

          <p
            className={`checkin__training__exercise__header${hasAdditionalHeader ? '--centered' : ''}`}
            style={{background: groupColor}}
          >

            Reps

          </p>

          <div className={`checkin__training__exercise__intensities-wrapper${executionMethod === TRAINING_EXECUTION_METHOD_FIXED_REPS ? '--vertical' : '--horizontal'}`}>

            {intensities}

          </div>

        </div>

        <div className="checkin__training__exercise__names">

          {hasAdditionalHeader &&
            <p
              className="checkin__training__exercise__header"
              style={{background: groupColor}}
            >

            </p>
          }

          <p
            className="checkin__training__exercise__header"
            style={{background: groupColor}}
          >

            Exercícios

          </p>

          {exerciseNames}

        </div>

        {showDificulties &&
          <div className="checkin__training__exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="checkin__training__exercise__header"
                style={{background: groupColor}}
              >

              </p>
            }

            <p
              className="checkin__training__exercise__header"
              style={{background: groupColor}}
            >

              Dificuldade

            </p>

            {difficulties}

          </div>
        }

        {showIntermediateDificulties &&
          <div className="checkin__training__exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="checkin__training__exercise__header"
                style={{background: groupColor}}
              >

              </p>
            }

            <p
              className="checkin__training__exercise__header"
              style={{background: groupColor}}
            >

              *

            </p>

            {intermediateDifficulties}

          </div>
        }

        {showAdvancedDificulties &&
          <div className="checkin__training__exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="checkin__training__exercise__header"
                style={{background: groupColor}}
              >

              </p>
            }

            <p
              className="checkin__training__exercise__header"
              style={{background: groupColor}}
            >

              AV

            </p>

            {advancedDifficulties}

          </div>
        }

        {showEasierOptions &&
          <div className="checkin__training__exercise__easier-options">

            {hasAdditionalHeader &&
              <p
                className="checkin__training__exercise__header"
                style={{background: groupColor}}
              >

              </p>
            }

            <p
              className="checkin__training__exercise__header"
              style={{background: groupColor}}
            >

              Variações

            </p>

            {easierOptions}

          </div>
        }

        {showReferences &&
          <div className="checkin__training__exercise__references">

            {hasAdditionalHeader &&
              <p
                className="checkin__training__exercise__header"
                style={{background: groupColor}}
              >

              </p>
            }

            <p
              className="checkin__training__exercise__header"
              style={{background: groupColor}}
            >

              Links

            </p>

            {references}

          </div>
        }

      </React.Fragment>
    );
  }

  getContentOptions() {
    if(this.state.criticalErrorMessage !== null) {
      return (
        <div className="checkin__warning-message">

          <i className="fas fa-exclamation checkin__warning-message__icon"></i>
          <p className="checkin__warning-message__text">{this.state.criticalErrorMessage}</p>

        </div>
      );
    }
    else if(this.state.selectedUnit === null) {
      if(this.state.active_units.length <= 0) {
        // <p className="checkin__main-message">
        //   Nenhum serviço contratado
        // </p>
        return (
          <div className="checkin__warning-message">

            <i className="fas fa-exclamation checkin__warning-message__icon"></i>
            <p className="checkin__warning-message__text">Nenhum serviço contratado.</p>

          </div>
        );
      }
      else {
        return (
          <React.Fragment>

            <h1 className="checkin__instructions">

              Selecione a unidade desejada:

            </h1>

            <div className="checkin__unit-selector">

              {this.getUnitOptions()}

            </div>

          </React.Fragment>
        );
      }
    }
    else if(this.state.selectedService === null) {
      return (
        <React.Fragment>

          <h1 className="checkin__instructions">

            Selecione o serviço desejado:

          </h1>

          <div className="checkin__service-selector">

            {this.getServiceOptions()}

          </div>

        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>

          <h1 className="checkin__instructions">

            Horários disponíveis do <span className="checkin__instructions__red-text">{this.state.selectedService}</span>:

          </h1>

          <div className="checkin__note">

            <p className="checkin__note__text">
              Somente as aulas dentro de um período de <strong>{AVAILABLE_CHECKIN_HOURS_AHEAD}h</strong> estarão visíveis e disponíveis para o check-in antecipado.
            </p>

          </div>

          <div className="checkin__time-list">

            {this.getDays()}

          </div>

        </React.Fragment>
      );
    }
  }

  getCheckinHeader() {
    if (this.state.access_rule === ACCESS_CONTROL_RULE_BLOCKED && this.state.access_status_description !== null && this.state.access_status_description.length > 0) {
      return (
        <aside className="checkin__notification-header">

          <div className="checkin__notification-header__title__wrapper">

            <h3 className="checkin__notification-header__title">Aviso</h3>

          </div>

          <div className="checkin__notification-header__text__wrapper">

            <p className="checkin__notification-header__text">

              Por favor, procure a recepção para regularizar o seguinte problema: <span className="checkin__notification-header__text--highlight">{this.state.access_status_description}</span>

            </p>

          </div>


        </aside>
      );
    }

    return null;
    // return (
    //   <aside className="checkin__promotion-banner">

    //     <a
    //       href="https://nutri.fydcentrodetreinamento.com.br"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       className="checkin__promotion-banner__link"
    //     >

    //       <div className="checkin__promotion-banner__image-wrapper">

    //         <img
    //           className="checkin__promotion-banner__image"
    //           srcSet={`https://fyd-public-images.nyc3.cdn.digitaloceanspaces.com/student_app/checkin_banner_860.jpg 860w,
    //                     https://fyd-public-images.nyc3.cdn.digitaloceanspaces.com/student_app/checkin_banner_860_2x.jpg 1720w,
    //                     https://fyd-public-images.nyc3.cdn.digitaloceanspaces.com/student_app/checkin_banner_650.jpg 650w,
    //                     https://fyd-public-images.nyc3.cdn.digitaloceanspaces.com/student_app/checkin_banner_650_2x.jpg 1300w,
    //                     https://fyd-public-images.nyc3.cdn.digitaloceanspaces.com/student_app/checkin_banner_540.jpg 540w,
    //                     https://fyd-public-images.nyc3.cdn.digitaloceanspaces.com/student_app/checkin_banner_540_2x.jpg 1080w,
    //                     https://fyd-public-images.nyc3.cdn.digitaloceanspaces.com/student_app/checkin_banner_400.jpg 400w,
    //                     https://fyd-public-images.nyc3.cdn.digitaloceanspaces.com/student_app/checkin_banner_400_2x.jpg 800w`}
    //           sizes="(max-width: 410px) 400px, (max-width: 680px) 650px, (max-width: 830px) 400px, (max-width: 1100px) 540px, (max-width: 1350px) 650px, 860px"
    //           alt="Quer ter resultados mais rápidoss? Conheça sobre nosso serviço de orientação nutricional no site https://nutri.fydcentrodetreinamento.com.br"
    //         />

    //       </div>

    //       <div className="checkin__promotion-banner__message">

    //         <p className="checkin__promotion-banner__message__line">

    //           Plano nutricional FYDNUTRI de 6 semanas

    //         </p>

    //         <p className="checkin__promotion-banner__message__line">

    //           TREINO PLANEJADO + PLANO ALIMENTAR = RESULTADO

    //         </p>

    //         <p className="checkin__promotion-banner__message__line--emphasis">

    //           nutri.fydcentrodetreinamento.com.br

    //           <span className="checkin__promotion-banner__message__phone">

    //             <i className="fa-brands fa-whatsapp checkin__promotion-banner__message__line__icon"></i>

    //             (16) 99747-5660

    //           </span>

    //         </p>

    //       </div>

    //     </a>

    //   </aside>
    // );
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="checkin">

          {this.getCheckinHeader()}

          {/* <aside className="checkin__banner">

            <div className="checkin__banner__wrapper">

              <h2 className="checkin__banner__title">Conheça o plano <span className="checkin__banner__title--green">NUTRICIONAL</span> da <span className="checkin__banner__title--emphasis">FYD</span></h2>

              <a
                href="https://nutri.fydcentrodetreinamento.com.br"
                target="_blank"
                rel="noopener noreferrer"
                className="checkin__banner__link-button"
              >
                Saiba mais
              </a>

            </div>

          </aside> */}

          {this.getContentOptions()}

        </div>

        <OverlayWindow
          className="checkin__overlay"
          visible={this.overlayIsVisible()}
          actions={(
            <div className="checkin__overlay__action-container">

              <DefaultMenuButton
                className="checkin__overlay__action-button"
                onClick={() => {
                  this.resetConfirmationWindow();
                }}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="checkin__overlay__action-button"
                onClick={() => {
                  this.proceedConfirmationWindow();
                }}
                text="Confirmar"
                disabled={this.state.pse === null}
                color="green"
              />

            </div>
          )}
        >

          <header className="checkin__overlay__header">

            <h3 className="checkin__overlay__header__title">
              {this.getOverlayHeader()}
            </h3>

          </header>

          <hr className="checkin__horizontal-rule" />

          <div className="checkin__overlay__content">

            <div className="checkin__scale">

              <h3 className="checkin__scale__title">

                PSR - Qual o seu nível de

                <span className="checkin__scale__highlighted-title">
                  cansaço FÍSICO?
                </span>

              </h3>

              <div className="checkin__overlay-note">

                <p className="checkin__overlay-note__text">
                  A escala de <strong>percepção subjetiva de recuperação</strong>, ou <strong>PSR</strong>, deve ser preenchido somente com base na sua
                  fadiga ou disposição <strong>física</strong>, e não com base no seu ânimo ou cansaço psicológico.
                </p>

              </div>

              <ul className="checkin__scale__ranges">

                <li className="checkin__scale__range" style={{flex: 3}}>

                  <div className="checkin__scale__range__image--3">

                    <i className="fas fa-dizzy checkin__scale__range__image-icon"></i>

                    {/* <p className="checkin__scale__range__image__text">
                      Expectativa de diminuição no desempenho
                    </p> */}

                  </div>

                  <div className="checkin__scale__range__options">

                    <button
                      className="checkin__scale__range__option--1"
                      onClick={() => this.setState({pse: 1})}
                      disabled={this.state.pse === 1}
                    >

                      <p className="checkin__scale__range__option-number">
                        1
                      </p>

                      {/* <p className="checkin__scale__range__option-text--flex">
                        Extremo cansaço
                      </p> */}

                    </button>

                    <button
                      className="checkin__scale__range__option--2"
                      onClick={() => this.setState({pse: 2})}
                      disabled={this.state.pse === 2}
                    >

                      <p className="checkin__scale__range__option-number">
                        2
                      </p>

                      {/* <p className="checkin__scale__range__option-text--flex">
                        Cansado
                      </p> */}

                    </button>

                    <button
                      className="checkin__scale__range__option--3"
                      onClick={() => this.setState({pse: 3})}
                      disabled={this.state.pse === 3}
                    >

                      <p className="checkin__scale__range__option-number">
                        3
                      </p>

                      {/* <p className="checkin__scale__range__option-text--flex">
                        Um pouco cansado
                      </p> */}

                    </button>

                  </div>

                  <div className="checkin__scale__range__description--3">

                    <p className="checkin__scale__range__description__text">
                      Muito cansado
                    </p>

                  </div>

                </li>

                <li className="checkin__scale__range" style={{flex: 4}}>

                  <div className="checkin__scale__range__image--7">

                    <i className="fas fa-meh checkin__scale__range__image-icon"></i>

                    {/* <p className="checkin__scale__range__image__text">
                      Expectativa de similaridade no desempenho
                    </p> */}

                  </div>

                  <div className="checkin__scale__range__options">

                    <button
                      className="checkin__scale__range__option--4"
                      onClick={() => this.setState({pse: 4})}
                      disabled={this.state.pse === 4}
                    >

                      <p className="checkin__scale__range__option-number">
                        4
                      </p>

                      {/* <p className="checkin__scale__range__option-text--flex">
                        Pouco recuperado
                      </p> */}

                    </button>

                    <button
                      className="checkin__scale__range__option--5"
                      onClick={() => this.setState({pse: 5})}
                      disabled={this.state.pse === 5}
                    >

                      <p className="checkin__scale__range__option-number">
                        5
                      </p>

                      {/* <p className="checkin__scale__range__option-text--flex">
                        Em recuperação
                      </p> */}

                    </button>

                    <button
                      className="checkin__scale__range__option--6"
                      onClick={() => this.setState({pse: 6})}
                      disabled={this.state.pse === 6}
                    >

                      <p className="checkin__scale__range__option-number">
                        6
                      </p>

                      {/* <p className="checkin__scale__range__option-text--flex">
                        Quase recuperado
                      </p> */}

                    </button>

                    <button
                      className="checkin__scale__range__option--7"
                      onClick={() => this.setState({pse: 7})}
                      disabled={this.state.pse === 7}
                    >

                      <p className="checkin__scale__range__option-number">
                        7
                      </p>

                      {/* <p className="checkin__scale__range__option-text--flex">
                        Recuperado
                      </p> */}

                    </button>

                  </div>

                  <div className="checkin__scale__range__description--7">

                    <p className="checkin__scale__range__description__text">
                      Levemente cansado
                    </p>

                  </div>

                </li>

                <li className="checkin__scale__range" style={{flex: 3}}>

                  <div className="checkin__scale__range__image--10">

                    <i className="fas fa-laugh-beam checkin__scale__range__image-icon"></i>

                    {/* <p className="checkin__scale__range__image__text">
                      Expectativa de um bom desempenho
                    </p> */}

                  </div>

                  <div className="checkin__scale__range__options">

                    <button
                      className="checkin__scale__range__option--8"
                      onClick={() => this.setState({pse: 8})}
                      disabled={this.state.pse === 8}
                    >

                      <p className="checkin__scale__range__option-number">
                        8
                      </p>

                      {/* <p className="checkin__scale__range__option-text--flex">
                        Bem recuperado
                      </p> */}

                    </button>

                    <button
                      className="checkin__scale__range__option--9"
                      onClick={() => this.setState({pse: 9})}
                      disabled={this.state.pse === 9}
                    >

                      <p className="checkin__scale__range__option-number">
                        9
                      </p>

                      {/* <p className="checkin__scale__range__option-text--flex">
                        Muito bem recuperado
                      </p> */}

                    </button>

                    <button
                      className="checkin__scale__range__option--10"
                      onClick={() => this.setState({pse: 10})}
                      disabled={this.state.pse === 10}
                    >

                      <p className="checkin__scale__range__option-number">
                        10
                      </p>

                      {/* <p className="checkin__scale__range__option-text--flex">
                        Altamente energético
                      </p> */}

                    </button>

                  </div>

                  <div className="checkin__scale__range__description--10">

                    <p className="checkin__scale__range__description__text">
                      Descansado
                    </p>

                  </div>

                </li>

              </ul>

            </div>

          </div>

        </OverlayWindow>

        <OverlayWindow
          className="checkin__overlay"
          visible={this.state.trainingDaySelected != null}
          actions={(
            <div className="checkin__overlay__action-container">

              <DefaultMenuButton
                className="checkin__overlay__action-button"
                onClick={() => this.setState({trainingDaySelected: null})}
                text="Fechar"
              />

            </div>
          )}
        >

          <header className="checkin__overlay__header">

            <h3 className="checkin__overlay__header__title">
              {this.getTrainingDayHeader()}
            </h3>

          </header>

          <hr className="checkin__horizontal-rule" />

          <div className="checkin__overlay__content">

            {this.getTrainingDayView()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

      </DefaultMenuLayout>
    );
  }
}

export default Checkin;

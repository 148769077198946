const DEFAULT_ERROR_MESSAGE = `Infelizmente estamos com problemas em nosso serviço. ` +
                              `Certifique-se de que esteja conectado na internet e, ` +
                              `por favor, tente novamente mais tarde.`;

const DEFAULT_UNKNOWN_ERROR_MESSAGE = `Erro não documentado. Por favor, entre em contato com ` +
                                      `o suporte para que isto seja corrigido`;

export {DEFAULT_ERROR_MESSAGE, DEFAULT_UNKNOWN_ERROR_MESSAGE};

const TRAINING_EXECUTION_METHOD_FIXED_REPS = 'Repetições fechadas';
const TRAINING_EXECUTION_METHOD_PIRAMIDAL = 'Piramidal';
const TRAINING_EXECUTION_METHOD_QRP = 'QRP';

const CLOCK_METHOD_STOPWATCH = 'Cronômetro';
const CLOCK_METHOD_TIMER = 'Contagem regressiva';
const CLOCK_METHOD_TABATA = 'Tabata';
const CLOCK_METHOD_SERIES = 'Séries';
const CLOCK_METHOD_CUSTOM = 'Personalizado';

const DIFFUCULTY_OPTION_EASY = 'Adaptado';
const DIFFUCULTY_OPTION_NORMAL = 'Normal';
const DIFFUCULTY_OPTION_INTERMEDIATE = '*';
const DIFFUCULTY_OPTION_ADVANCED = 'AV';

const SERVICE_DEFAULT_TRAINING = 'Cross FYD';
const SERVICE_PILATES = 'Fyd Pilates';
const SERVICE_GYM = 'Musculação';
const SERVICE_BIKE = 'Fyd Bike';
const SERVICE_CARDIO = 'Cardio';
const SERVICE_CORE = 'Core';
const SERVICE_BOOTCAMP = 'FYD Run Bootcamp';

const ALERT_FUNCTIONAL_CHECKIN_COUNT_THRESHOLD = 4;
const MEDIUM_FUNCTIONAL_CHECKIN_COUNT_THRESHOLD = 8;
const ALERT_PILATES_CHECKIN_COUNT_THRESHOLD = 2;
const MEDIUM_PILATES_CHECKIN_COUNT_THRESHOLD = 4;
const ALERT_GYM_CHECKIN_COUNT_THRESHOLD = 3;
const MEDIUM_GYM_CHECKIN_COUNT_THRESHOLD = 6;
const ALERT_BIKE_CHECKIN_COUNT_THRESHOLD = 3;
const MEDIUM_BIKE_CHECKIN_COUNT_THRESHOLD = 6;
const ALERT_CARDIO_CHECKIN_COUNT_THRESHOLD = 3;
const MEDIUM_CARDIO_CHECKIN_COUNT_THRESHOLD = 6;
const ALERT_BOOTCAMP_CHECKIN_COUNT_THRESHOLD = 3;
const MEDIUM_BOOTCAMP_CHECKIN_COUNT_THRESHOLD = 6;
const ALERT_CORE_CHECKIN_COUNT_THRESHOLD = 3;
const MEDIUM_CORE_CHECKIN_COUNT_THRESHOLD = 6;

const CUSTOM_TRAINING_MIN_DATE_OFFSET = 27;

const AVAILABLE_CHECKIN_HOURS_AHEAD = 16;

const NUTRIENT_PROTEIN_ID = 1;
const NUTRIENT_FAT_ID = 2;
const NUTRIENT_CARBOHYDRATE_ID = 3;
const NUTRIENT_FIBER_ID = 4;

const FOOD_MEASUREMENT_GRAM_ID = 68;

const DEFAULT_FOOD_INFO_SOURCE = {
  id: 3,
  shortname: 'Manual',
};

const DEFAULT_ENERGY_UNIT = {
  id: 1,
  shortname: 'kcal',
};

const DEFAULT_WEIGHT_UNIT = {
  id: 1,
  shortname: 'g',
};

const FORM_ANSWER_TYPE_SHORT_TEXT_ID = 1;
const FORM_ANSWER_TYPE_LONG_TEXT_ID = 2;
const FORM_ANSWER_TYPE_HORIZONTAL_SELECT_ID = 3;

const FYD_FORM_NUTRI_FOLLOWUP_ID = 1;

const FYD_FORM_FOLLOWUP_QUESTION_DIET_ID = 4;
const FYD_FORM_FOLLOWUP_QUESTION_TRAINING_ID = 5;
const FYD_FORM_FOLLOWUP_QUESTION_STRESS_ID = 1;
const FYD_FORM_FOLLOWUP_QUESTION_SLEEP_ID = 2;
const FYD_FORM_FOLLOWUP_QUESTION_WATER_ID = 3;

const SUPPORT_CATEGORY_FP_DIET_ID = 1;
const SUPPORT_CATEGORY_FP_GENERAL_ID = 2;
const SUPPORT_CATEGORY_PT_PERIOD_ID = 3;
const SUPPORT_CATEGORY_PT_GENERAL_ID = 4;

const SUPPORT_STATUS_OPEN_ID = 1;
const SUPPORT_STATUS_CLOSED_ID = 2;
const SUPPORT_STATUS_AWAITING_RESPONSE_ID = 3;

const SUPPORT_MAIN_SECTIONS = [
  'Nutritional',
  'Personal training',
  'General'
];

const ACCESS_CONTROL_RULE_BLOCKED = 'Blocked';

export {
  TRAINING_EXECUTION_METHOD_FIXED_REPS,
  TRAINING_EXECUTION_METHOD_PIRAMIDAL,
  TRAINING_EXECUTION_METHOD_QRP,
  CLOCK_METHOD_STOPWATCH,
  CLOCK_METHOD_TIMER,
  CLOCK_METHOD_TABATA,
  CLOCK_METHOD_SERIES,
  CLOCK_METHOD_CUSTOM,
  DIFFUCULTY_OPTION_EASY,
  DIFFUCULTY_OPTION_NORMAL,
  DIFFUCULTY_OPTION_INTERMEDIATE,
  DIFFUCULTY_OPTION_ADVANCED,
  SERVICE_DEFAULT_TRAINING,
  SERVICE_PILATES,
  SERVICE_GYM,
  SERVICE_BIKE,
  SERVICE_CARDIO,
  SERVICE_CORE,
  SERVICE_BOOTCAMP,
  ALERT_FUNCTIONAL_CHECKIN_COUNT_THRESHOLD,
  MEDIUM_FUNCTIONAL_CHECKIN_COUNT_THRESHOLD,
  ALERT_PILATES_CHECKIN_COUNT_THRESHOLD,
  MEDIUM_PILATES_CHECKIN_COUNT_THRESHOLD,
  ALERT_GYM_CHECKIN_COUNT_THRESHOLD,
  MEDIUM_GYM_CHECKIN_COUNT_THRESHOLD,
  ALERT_BIKE_CHECKIN_COUNT_THRESHOLD,
  MEDIUM_BIKE_CHECKIN_COUNT_THRESHOLD,
  ALERT_CARDIO_CHECKIN_COUNT_THRESHOLD,
  MEDIUM_CARDIO_CHECKIN_COUNT_THRESHOLD,
  ALERT_BOOTCAMP_CHECKIN_COUNT_THRESHOLD,
  MEDIUM_BOOTCAMP_CHECKIN_COUNT_THRESHOLD,
  ALERT_CORE_CHECKIN_COUNT_THRESHOLD,
  MEDIUM_CORE_CHECKIN_COUNT_THRESHOLD,
  CUSTOM_TRAINING_MIN_DATE_OFFSET,
  AVAILABLE_CHECKIN_HOURS_AHEAD,
  NUTRIENT_PROTEIN_ID,
  NUTRIENT_FAT_ID,
  NUTRIENT_CARBOHYDRATE_ID,
  NUTRIENT_FIBER_ID,
  DEFAULT_FOOD_INFO_SOURCE,
  DEFAULT_ENERGY_UNIT,
  DEFAULT_WEIGHT_UNIT,
  FOOD_MEASUREMENT_GRAM_ID,
  FORM_ANSWER_TYPE_SHORT_TEXT_ID,
  FORM_ANSWER_TYPE_LONG_TEXT_ID,
  FORM_ANSWER_TYPE_HORIZONTAL_SELECT_ID,
  FYD_FORM_NUTRI_FOLLOWUP_ID,
  FYD_FORM_FOLLOWUP_QUESTION_DIET_ID,
  FYD_FORM_FOLLOWUP_QUESTION_TRAINING_ID,
  FYD_FORM_FOLLOWUP_QUESTION_STRESS_ID,
  FYD_FORM_FOLLOWUP_QUESTION_SLEEP_ID,
  FYD_FORM_FOLLOWUP_QUESTION_WATER_ID,
  SUPPORT_CATEGORY_FP_DIET_ID,
  SUPPORT_CATEGORY_FP_GENERAL_ID,
  SUPPORT_STATUS_OPEN_ID,
  SUPPORT_STATUS_CLOSED_ID,
  SUPPORT_STATUS_AWAITING_RESPONSE_ID,
  SUPPORT_MAIN_SECTIONS,
  SUPPORT_CATEGORY_PT_PERIOD_ID,
  SUPPORT_CATEGORY_PT_GENERAL_ID,
  ACCESS_CONTROL_RULE_BLOCKED
};

// APIS
const STATE_API = '/request_state';

const AUTHENTICATE_API = '/autenticar';
const IS_AUTHENTICATED_API = '/autenticado';
const SIGN_OUT_API = '/deslogar';

const TRAINING_CALENDAR_GET_API = '/training-calendar/';

const CHECKIN_CONFIRM_POST_API = '/confirm-class-checkin';
const CHECKIN_CANCEL_POST_API = '/cancel-class-checkin';

const PASSWORD_RECOVERY_POST_API = '/password-recovery';
const RESET_PASSWORD_POST_API = '/reset-password/';

const VALIDATE_EMAIL_POST_API = '/validate-email/';

const RECENT_TRAINING_GET_API = '/recent-trainings';
const CUSTOM_TRAINING_GET_API = '/custom-training/';
const CUSTOM_TRAINING_POST_API = '/custom-training';
const CUSTOM_TRAINING_PATCH_API = '/custom-training/';
const CUSTOM_TRAINING_DELETE_API = '/custom-training/';

const DEFAULT_TRAINING_GET_API = '/default-training/';
const DEFAULT_TRAINING_PATCH_API = '/default-training/';

const DEFAULT_TRAININGS_GET_API = '/default-trainings/';

const EXERCISE_REFERENCE_GET_API = '/exercise-references/'
const EXERCISE_REFERENCES_POST_API = '/exercise-references';

const CURRENT_TRAINING_PERIODS_GET_API = '/current-training-periods/';

const UPDATE_EMAIL_POST_API = '/update-email/';

const ACTIVE_UNITS_GET_API = '/available-active-units';

const HOME_TRAININGS_GET_API = '/home-trainings';
const HOME_TRAINING_FINISH_POST_API = '/home-training';

const PERSONAL_TRAINING_GET_API = '/personal-training';
const PERSONAL_TRAINING_POST_API = '/personal-training';

const PERSONAL_TRAINING_DATA_GET_API = '/personal-training-data/';
const PERSONAL_TRAINING_DATA_PATCH_API = '/personal-training-data/';
const PERSONAL_TRAINING_DATA_DELETE_API = '/personal-training-data/';

const PERSONAL_TRAINING_REPORT_GET_API = '/personal-training-data/{id}/report';

const RAC_GET_API = '/rac';

const MENU_STATUS_GET_API = '/student-menu-status'

const EXERCISE_ACTIVITIES_GET_API = '/exercise-activities';

const FOOD_PRESCRIPTION_GET_API = '/food-prescription';

const WEIGHT_MEASUREMENT_POST_API = '/weight-measurement';
const WEIGHT_MEASUREMENT_DELETE_API = '/weight-measurement/';

const ACTIVE_GYM_TRAINING_DAY_POST_API = '/personal-training/active-gym-training';

const PERSONAL_RECORD_EXERCISES_GET_API = '/personal-record/{unit_id}/exercises';

const NUTRITIONAL_FOLLOWUP_FORM_GET_API = '/food-prescription/followup-form';

const FYD_FORM_POST_API = '/fyd-form';
const MONTHLY_SATISFACTION_SURVEY_POST_API = '/fyd-form/monthly-satisfaction-survey';

const SUPPORT_TICKETS_GET_API = '/support-tickets';
const SUPPORT_TICKET_POST_API = '/support-ticket';
const CLOSE_SUPPORT_TICKET_POST_API = '/support-ticket/{support_ticket_id}/close-ticket';
const SUPPORT_MESSAGE_POST_API = '/support-ticket/{support_ticket_id}/message';
const SUPPORT_MESSAGE_READ_PATCH_API = '/support-message/{support_message_id}/mark-as-read';

const ACCESS_CONTROL_ACCESS_RULE_GET_API = '/access-control/access-rule/';

export {
  STATE_API,
  AUTHENTICATE_API,
  IS_AUTHENTICATED_API,
  SIGN_OUT_API,
  TRAINING_CALENDAR_GET_API,
  CHECKIN_CONFIRM_POST_API,
  CHECKIN_CANCEL_POST_API,
  PASSWORD_RECOVERY_POST_API,
  RESET_PASSWORD_POST_API,
  VALIDATE_EMAIL_POST_API,
  RECENT_TRAINING_GET_API,
  CUSTOM_TRAINING_GET_API,
  CUSTOM_TRAINING_POST_API,
  CUSTOM_TRAINING_PATCH_API,
  CUSTOM_TRAINING_DELETE_API,
  DEFAULT_TRAINING_GET_API,
  DEFAULT_TRAINING_PATCH_API,
  DEFAULT_TRAININGS_GET_API,
  EXERCISE_REFERENCE_GET_API,
  EXERCISE_REFERENCES_POST_API,
  CURRENT_TRAINING_PERIODS_GET_API,
  UPDATE_EMAIL_POST_API,
  ACTIVE_UNITS_GET_API,
  HOME_TRAININGS_GET_API,
  HOME_TRAINING_FINISH_POST_API,
  PERSONAL_TRAINING_GET_API,
  PERSONAL_TRAINING_DATA_GET_API,
  PERSONAL_TRAINING_DATA_PATCH_API,
  PERSONAL_TRAINING_DATA_DELETE_API,
  PERSONAL_TRAINING_REPORT_GET_API,
  RAC_GET_API,
  MENU_STATUS_GET_API,
  EXERCISE_ACTIVITIES_GET_API,
  FOOD_PRESCRIPTION_GET_API,
  WEIGHT_MEASUREMENT_POST_API,
  WEIGHT_MEASUREMENT_DELETE_API,
  ACTIVE_GYM_TRAINING_DAY_POST_API,
  PERSONAL_RECORD_EXERCISES_GET_API,
  NUTRITIONAL_FOLLOWUP_FORM_GET_API,
  FYD_FORM_POST_API,
  MONTHLY_SATISFACTION_SURVEY_POST_API,
  SUPPORT_TICKET_POST_API,
  SUPPORT_TICKETS_GET_API,
  CLOSE_SUPPORT_TICKET_POST_API,
  SUPPORT_MESSAGE_POST_API,
  SUPPORT_MESSAGE_READ_PATCH_API,
  ACCESS_CONTROL_ACCESS_RULE_GET_API
};

// PATHS
const MAIN_PATH = '/';
const CHECKIN_PATH = '/checkin';

const FORGOT_PASSWORD_PATH = '/esqueci-minha-senha';

const PASSWORD_RECOVERY_PATH = '/definir-senha/';
const VALIDATE_ACCOUNT_PATH = '/validar-conta/';
const TRAININGS_PATH = '/meus-treinos';
const ADD_CUSTOM_TRAINING_PATH = '/cadastrar-treino-particular';
const EDIT_CUSTOM_TRAINING_PATH = '/editar-treino-particular/';
const EDIT_DEFAULT_TRAINING_PATH = '/editar-treino/';
const TRAINING_DAY_REPORT_PATH = '/comparativo-de-treino/';

const EMAIL_UPDATE_PATH = '/alterar-email/';

const HOME_TRAINING_PATH = '/treinos-para-casa';

const PERSONAL_TRAINING_PATH = '/treino-personalizado';
const PERSONAL_TRAINING_EDIT_PATH = '/treino-personalizado/';

const PERSONAL_TRAINING_SUPPORT_PATH = '/treino-personalizado/suporte';
const PERSONAL_TRAINING_SUPPORT_REGISTER_PATH = '/treino-personalizado/registrar-duvida';

const PERSONAL_TRAINING_REPORT_PATH = '/comparativo-de-treino-de-personal/';

const FOOD_PRESCRIPTION_PATH = '/acompanhamento-nutricional';

const PERSONAL_RECORD_PATH = '/personal-record';

const NUTRITIONAL_SUPPORT_PATH = '/acompanhamento-nutricional/suporte';
const NUTRITIONAL_SUPPORT_REGISTER_PAGE = '/acompanhamento-nutricional/registrar-duvida';

export {
  MAIN_PATH,
  CHECKIN_PATH,
  FORGOT_PASSWORD_PATH,
  PASSWORD_RECOVERY_PATH,
  VALIDATE_ACCOUNT_PATH,
  TRAININGS_PATH,
  ADD_CUSTOM_TRAINING_PATH,
  EDIT_CUSTOM_TRAINING_PATH,
  EDIT_DEFAULT_TRAINING_PATH,
  TRAINING_DAY_REPORT_PATH,
  EMAIL_UPDATE_PATH,
  HOME_TRAINING_PATH,
  PERSONAL_TRAINING_PATH,
  PERSONAL_TRAINING_POST_API,
  PERSONAL_TRAINING_EDIT_PATH,
  PERSONAL_TRAINING_REPORT_PATH,
  FOOD_PRESCRIPTION_PATH,
  PERSONAL_RECORD_PATH,
  NUTRITIONAL_SUPPORT_PATH,
  NUTRITIONAL_SUPPORT_REGISTER_PAGE,
  PERSONAL_TRAINING_SUPPORT_PATH,
  PERSONAL_TRAINING_SUPPORT_REGISTER_PATH
};
